import { useUiStateStore } from '@/@core/services/uiStateStore';
import { reactive, toRefs } from '@vue/composition-api';
import { useUnitStore } from '../../services/store';

type UnitModelState = {
  headers: any[];
  isLoading: boolean;
  showFormDialog: boolean;
  showDeleteDialog: boolean;
  isFormValid: boolean;
  formData: any;
  search: string;
};

const state = reactive<UnitModelState>({
  headers: [
    {
      text: 'Nama Satuan/Unit',
      value: 'name'
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      width: '1%'
    }
  ],
  isLoading: false,
  showFormDialog: false,
  showDeleteDialog: false,
  isFormValid: false,
  formData: null,
  search: ''
});

export default function useUnitModel() {
  const store = useUnitStore();

  const onGetManyUnit = async () => {
    state.isLoading = true;

    try {
      state.isLoading = true;
      await store.getManyUnit();
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onCreateUnit = async (data: any) => {
    state.isLoading = true;

    try {
      await store.postUnit(data);
      useUiStateStore().createSnackbar({
        message: `Satuan ${data.name} berhasil ditambahkan.`
      });

      state.showFormDialog = false;
      await onGetManyUnit();
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onUpdateUnit = async (data: any) => {
    state.isLoading = true;

    try {
      await store.putUnit(data);
      useUiStateStore().createSnackbar({
        message: `Satuan ${data.name} berhasil diubah.`
      });
      state.showFormDialog = false;
      await onGetManyUnit();
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const createUnit = () => {
    state.formData = null;
    state.showFormDialog = true;
  };

  const editUnit = (unit: any) => {
    state.formData = { ...unit };
    state.showFormDialog = true;
  };

  const deleteUnit = (unit: any) => {
    state.formData = unit;
    state.showDeleteDialog = true;
  };

  const onDeleteUnit = async () => {
    state.isLoading = true;

    try {
      await store.deleteUnit(state.formData);
      const data: any = state.formData || {};
      useUiStateStore().createSnackbar({
        message: `Satuan ${data.name} berhasil dihapus.`
      });
      state.showDeleteDialog = false;
      state.formData = null;
      await onGetManyUnit();
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onSubmitForm = () => {
    const isEdit = (state.formData as any)?.id;

    if (isEdit) onUpdateUnit(state.formData);
    else onCreateUnit(state.formData);
  };

  return {
    ...toRefs(state),
    store,
    onGetManyUnit,
    onSubmitForm,
    onDeleteUnit,
    deleteUnit,
    editUnit,
    createUnit
  };
}

export { useUnitModel };
